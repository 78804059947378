.portfolio {
    padding-top: 20px;
    padding-bottom: 70px;
}

.portfolio .cards {
    padding-left: 300px;
    padding-top: 50px;
}

.porfolio-container {
    background-color: rgb(224, 225, 233);
    min-width: 250px;
    max-width: 400px;
    height: auto;
    padding-left: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
}

.portfolio-title {
    color: #0A5485;
}

.web-animation {
    width: 500px;
    padding-left: 200px;
}
.mobile-animation{
    width: 400px;
}
.porfolio-mobile-container{
    margin-left: 100px;
    background-color: rgb(224, 225, 233);
    min-width: 250px;
    max-width: 400px;
    height: auto;
    padding-left: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
}