.about-us {
    padding-top: 20px;
}

.about {
    margin-top: 30px;
    background-color: aliceblue;
    padding-bottom: 20px;
    margin-left: 150px;
    margin-right: 150px;
}

.about-container{
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    display: flex;
    column-gap: 40px;
}

.about-team-image1 {
    width: 700px;
    height: 350px;
}

.Our-mission {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
}