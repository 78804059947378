.service{
  padding-top: 20px;
  padding-bottom: 100px;
}

.service-card{
  display: flex;
  column-gap: 60px;
  padding-top: 50px;
  margin-left: 100px;
}

.skill-card {
  padding: 20px;
  width:280px;
  height: 330px;
  background-color: #0A5485;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}
.skill-card:hover .skill-card__header {
  height: 0px;
}


.skill-card:hover .skill-card__body {
  height: 170px;
}


.skill-card .skill-card__header {
  position: relative;
  background-color: #dde3eb;
  border-radius: 1px;
  height: 170px;
  margin: -20px -20px 20px -20px;
  transition: height .5s;
  overflow: hidden;
}
.skill-card .skill-card__title{
 color: #fff;
 padding-bottom: 20px;
}
.skill-card .skill-card__header .skill-card__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: block;
  width: 280px;
  height: 170px;
  transition: transform .5s;
}

.skill-card .skill-card__body {
  height: 40px;
  transition: height .5s;
  overflow: hidden;
  color:  #fff;
}
.skill-card .skill-card__body button{
  padding:5px 10px;
  text-decoration: none;
}
.skill-card .skill-card__body button .read-more{
  text-decoration: none;
}