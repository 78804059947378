.top {
  width: 100%;
  height: 75px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  font-family: "Josefin Sans", sans-serif;
  color: aliceblue;
  background-color: #0A5485;
}

.link {
  color: aliceblue;
  text-decoration: none;
}

.mdits-title {
  margin-left: 65px;
}

.mdits-title-name {
  font-size: 14px;
  padding-left: 10px;
}

.topCenter {
  flex: 6;
}

.topList {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.topListItem {
  margin-right: 20px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}

.topListItem:hover {
  color: gray;
}

.navbar-item-spacer {
  flex: 12 2 auto;
}

.logo {
  padding-top: 10px;
  color: #0A5485;
  background-color: rgb(235, 233, 233);
  height: 8vh;
  text-align: center;
  font-size: 20px;
}

@media (min-width:768px) and (max-width:1080px){
  .top {
    flex-direction: column;
    margin: 0;
  }

}
@media  (max-width:420px){
  .top {
    flex-direction: column;
    margin: 0;
  }
  .logo {
    font-size: 10px;
  }

}