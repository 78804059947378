.footer {
    color: aliceblue;
    background-color: #0A5485;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50px;
    text-align: center;
    width: 100%;
}