.team{
    padding-top: 20px;
    padding-bottom: 100px;
}
.team .team-images{
    padding-top: 20px;
    padding-left: 250px;
    display: flex;
    column-gap: 100px;
    padding-bottom: 30px;
}
.team .images{
    padding-left: 250px;
    display: flex;
    column-gap: 20px;
}