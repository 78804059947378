.contact {
  padding-top: 20px;
  padding-bottom: 100px;
}

.contact .contact-us {
  text-align: center;
}

.contact .contact-us-details {
  padding-top: 50px;
  margin-left: 200px;
  display: flex;
  column-gap: 250px;
}
.contact .google-maps {
  padding-top: 20px;
}
.contact-us{
  margin: auto;
  background-color: #0A5485;
  color: #fff;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  height: 100px;
  column-gap: 50px;
}