.home {
    padding-top: 10px;
    padding-bottom: 50px;
}

.developments {
    display: flex;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 200px;
}

.development-image {
    width: 150px;
}

.development-web-name {
    padding-left: 20px;
    text-decoration: none;
}

.development-mobile-name {
    padding-left: 10px;
    text-decoration: none;
}

.development-ux-name {
    padding-left: 40px;
    text-decoration: none;
}

.success-container {
    background-color: rgb(215, 219, 228);
    margin-top: 50px;
    padding-bottom: 20px;
}

.team-image {
    margin: auto;
    width: 400px;
    margin-left: 100px;
}

.team-images {
    display: grid;
    width: 50px;
}

.team-text {
    float: right;
    padding-right: 200px;
}

.cards-header {
    text-align: center;
}

.cards {
    padding-left: 100px;
    padding-right: 50px;
    display: flex;
    column-gap: 20px;
}

.card-container {
    background-color: rgb(233, 237, 241);
    min-width: 250px;
    max-width: 400px;
    height: auto;
    border-radius: 5px;
}

header {
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.card-image {
    margin: auto;
    width: 100px;
    border: solid white 2px;
    border-radius: 50%;
    margin-top: 75px;
}

.name {
    text-align: start;
}